// LanguageContext.js
import React, { createContext, useState } from 'react';
import enMessages from '../locales/en.json';
import zhMessages from '../locales/zh.json';

// Define messages object containing translations for each language
const messages = {
  en: enMessages,
  zh: zhMessages
};

// Create a context for managing language state and providing translation messages
export const LanguageContext = createContext();

// LanguageProvider component to wrap around the entire application
export const LanguageProvider = ({ children }) => {
  // Define initial language state (default: English)
  const [locale, setLocale] = useState('en');

  // Function to toggle between English and Chinese
  const toggleLocale = () => {
    setLocale(prevLocale => (prevLocale === 'en' ? 'zh' : 'en'));
  };

  // Provide the language state and messages to the entire application using context
  return (
    <LanguageContext.Provider value={{ locale, messages: messages[locale], toggleLocale }}>
      {children}
    </LanguageContext.Provider>
  );
};
