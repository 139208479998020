import React, {useContext} from "react";
import "./Experience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";
import {Fade} from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";

export default function Experience() {
  const {isDark} = useContext(StyleContext);
  const { t, i18n } = useTranslation();
  if (workExperiences.display) {
    return (
      <div className={isDark ? "dark-mode section-container experience-container" : "section-container experience-container"} id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-section " id="experience-section">
            <div>
              <h2 className="experience-heading section-heading">
                {t("experience.title")}
              </h2>
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => {
                  let identifier = card['identifier']
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      experience={{
                        title: t(`experience.${identifier}.title`),
                        employmentType: t(`experience.${identifier}.employmentType`),
                        companyName: t(`experience.${identifier}.companyName`),
                        companylogo: card.companylogo,
                        date: t(`experience.${identifier}.date`),
                        location: t(`experience.${identifier}.location`),
                        desc: t(`experience.${identifier}.desc`),
                        skills: t(`experience.${identifier}.skills`)
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
