/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: false, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  displayGreeting: true, // Set false to hide this section, defaults to true,
  username: "Zheng Ye",
  resumeLink:
    "https://file.zhengye.me/郑烨_简历.pdf",
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/csyezheng",
  linkedin: "https://www.linkedin.com/in/csyezheng/",
  gmail: "csyezheng@gmail.com",
  gitlab: "https://gitlab.com/yezheng",
  facebook: "https://www.facebook.com/ye.zheng.pm/",
  stackoverflow: "https://stackoverflow.com/users/5694480/ye-zheng",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  display: true, // Set false to hide this section, defaults to true
  title: "Skills",
  skillsData: [
    {
      category: 'Programming',
      skills: ["Python", "Go", "JavaScript", "Django", "Gin", "React", "Continuous Integration"],
    },
    {
      category: 'Product',
      skills: ["Product Strategy", "User Experience (UX) Design", "Agile Methodologies", "Requirement Gathering", "Product Metrics"],
    },
    {
      category: 'Operations',
      skills: ["Process Improvement", "Quality Assurance", "Risk Management", "Performance Monitoring"],
    },
    {
      category: 'Others',
      skills: ["Communication", "Leadership", "Problem-Solving", "Adaptability", "Time Management", "Collaboration"],
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const expertiseStacks = {
  viewExpertise: true, //Set it to true to show Proficiency Section
  expertiseData: [
    'Online Education and Training', 'CRM (Customer Relationship Management)', 'Intelligent Customer Service',
  ],
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      identifier: "Huawei Business Analyst",
      companylogo: require("./assets/images/HuaweiLogo.png"),
    },
    {
      identifier: "Huawei Software Engineer",
      companylogo: require("./assets/images/HuaweiLogo.png"),
    },
    {
      identifier: "Boyaa Data Engineer",
      companylogo: require("./assets/images/boyaaLogo.jpg"),
    }
  ]
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Hebei GEO University in China",
      logo: require("./assets/images/HebeiGEOUniversityLogo.png"),
    },
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const projects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      projectName: "Saayahealth",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://blog.zhengye.me/post/programming/programming-languages/go/tutorial/",
      title: "The Go Tutorial",
      description:
        "Tutorial for learning go language. In this tutorial, you'll get a brief introduction to Go programming. A multi-part tutorial that introduces common programming language features from the Go perspective."
    },
    {
      url: "https://blog.zhengye.me/post/others/arch-system-maintenance/",
      title: "Arch Linux System maintenance",
      description:
        "Regular system maintenance is necessary for the proper functioning of Arch over a period of time. Timely maintenance is a practice many users get accustomed to."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  number: "+86 13510290827",
  email_address: "csyezheng@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  expertiseStacks,
  workExperiences,
  openSource,
  projects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
