import React, {useContext} from "react";
import "./Education.scss";
import EducationCard from "../../components/educationCard/EducationCard";
import {educationInfo} from "../../portfolio";
import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";

export default function Education() {
  const {isDark} = useContext(StyleContext);
  const { t, i18n } = useTranslation();
  if (educationInfo.display) {
    return (
      <div className={isDark ? "dark-mode section-container education-container" : "section-container education-container"} id="education">
        <div className="education-section " id="education-section">
          <h2 className="education-heading section-heading">
            {t("education.title")}
          </h2>
          <div className="education-card-container">
            {educationInfo.schools.map((item, index) => (
              <EducationCard key={index} 
              school={{
                "schoolName": t(`education.${item['schoolName']}.schoolName`),
                "logo": item.logo,
                "subHeader": t(`education.${item['schoolName']}.subHeader`),
                "duration": t(`education.${item['schoolName']}.duration`),
              }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
}
