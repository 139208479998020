import React, { useContext } from "react";
import { skillsSection } from "../../portfolio";
import { Fade } from "react-awesome-reveal";
import { Typography, Card, CardContent, Grid } from "@mui/material";

import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";
import './Skills.scss'; // Importing CSS file


const Skills = () => {
  const skillsData = skillsSection.skillsData;

  const { isDark } = useContext(StyleContext);
  const { t, i18n } = useTranslation();

  if (!skillsSection.display) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div
        className={
          isDark
            ? "dark-mode section-container skills-container"
            : "section-container skills-container"
        }
        id="skills"
      >
        <div style={{ paddingBottom: "1rem" }}>
          <h2 className="skills-heading section-heading">
            {t("skills.title")}
          </h2>
          <Grid container spacing={4}
            className={
              isDark
                ? "dark-mode skills-content"
                : "skills-content"
            }
          >
            {skillsData.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    bgcolor: isDark ? "dark.main" : "background.paper",
                  }}
                  className={
                    isDark
                      ? "dark-mode skill-card"
                      : "skill-card"
                  }
                >
                  <CardContent style={{"minWidth": "200px"}}> 
                      <h3 className="skill_category">{t(`skills.${item["category"]}`)}</h3>
                      <ul className="no-bullets">
                        {item.skills.map((skill, skillIndex) => (
                          <li key={skillIndex}>{t(`skills.${item["category"]}_skills.${item.skills[skillIndex]}`)}</li>
                        ))}
                      </ul>
                  </CardContent>
                </Card>
              </Grid>
          ))}
          </Grid>
        </div>
      </div>
    </Fade>
  );
}

export default Skills;
