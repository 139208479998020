import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.scss";
import HomePage from "./pages/HomePage/HomePage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage/ProjectDetailsPage";

function App() {
  return (
    <Router>
    <Routes>
      <Route path="" element={<HomePage />} />
      <Route path="/projects/" element={<ProjectsPage />} />
      <Route path="/projects/:projectId/" element={<ProjectDetailsPage />} />
    </Routes>
  </Router>
  );
}

export default App;
