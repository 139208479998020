import React, { useContext } from "react";
import "./Expertise.scss";
import { expertiseStacks } from "../../portfolio";
import { Fade } from "react-awesome-reveal";
import { Typography, Card, CardContent, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";

export default function Expertise() {
  const { isDark } = useContext(StyleContext);
  const { t, i18n } = useTranslation();
  if (!expertiseStacks.viewExpertise) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div
        className={
          isDark
            ? "dark-mode section-container expertise-container"
            : "section-container expertise-container"
        }
        id="expertise"
      >
        <div style={{ paddingBottom: "1rem" }}>
          <h2 className="expertise-heading section-heading">
            {t("expertise.title")}
          </h2>
          <Grid container spacing={4}
            className={
              isDark
                ? "dark-mode expertise-content"
                : "expertise-content"
            }
          >
            {expertiseStacks.expertiseData.map((expertise_name, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    bgcolor: isDark ? "dark.main" : "background.paper",
                  }}
                  className={
                    isDark
                      ? "dark-mode expertise-card"
                      : "expertise-card"
                  }
                >
                  <CardContent> 
                     <h3 className="expertiseTitle">{t(`expertise.${expertise_name}.title`)}</h3>
                    <Typography variant="body1">{t(`expertise.${expertise_name}.details`)}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Fade>
  );
}
