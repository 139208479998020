import React, {useState, createRef} from "react";
import "./ExperienceCard.scss";

export default function ExperienceCard({experience, isDark}) {
  return (
    <div className={isDark ? "dark experience-card" : "experience-card"}>
      <div className="experience-logo">
        <img src={experience.companylogo} alt={experience.companyName} width={64} height={64} loading="lazy" />
      </div>
      <div className="experience-details">
        <div className="experience-header">
          <h3 className="experience-title">{experience.title}</h3>
        </div>
        <p className="experience-company-employment-type">
          <span className="experience-company">{experience.companyName}</span>
          <span> - </span>
          <span className="experience-employment-type">{experience.employmentType}</span>
        </p>
        <p className="experience-duration">{experience.date}</p>
        <p className="experience-location">{experience.location}</p>
        <p className="experience-description">{experience.desc}</p>
      </div>
    </div>
  );
}
