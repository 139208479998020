import React, {lazy, Suspense, useContext, useEffect, useState} from "react";
import "./OpenSourceProjects.scss";
import Button from "../../components/button/Button";
import {openSource, socialMediaLinks} from "../../portfolio";
import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";
import Loading from "../loading/Loading";

const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN;
const GITHUB_USERNAME = process.env.REACT_APP_GITHUB_USERNAME;

export default function OpenSourceProjects() {
    const { isDark } = useContext(StyleContext);
    const { t, i18n } = useTranslation();

    const GithubRepoCard = lazy(() =>
        import("../../components/githubRepoCard/GithubRepoCard")
    );
    const FailedLoading = () => null;
    const renderLoader = () => <Loading/>;
    const [repo, setrepo] = useState([]);

    useEffect(() => {
        var body = JSON.stringify({
            query: `
{
  user(login:"${GITHUB_USERNAME}") { 
    name
    bio
    avatarUrl
    location
    pinnedItems(first: 6, types: [REPOSITORY]) {
      totalCount
      edges {
          node {
            ... on Repository {
              name
              description
              forkCount
              stargazers {
                totalCount
              }
              url
              id
              diskUsage
              primaryLanguage {
                name
                color
              }
            }
          }
        }
      }
    }
}
`
        });

        const getRepoData = () => {
            fetch("https://api.github.com/graphql", {
                method: "POST",
                body: body,
                headers: {
                    "Authorization": `Bearer ${GITHUB_TOKEN}`,
                    "User-Agent": "Node"
                }
            })
                .then(result => {
                    if (result.ok) {
                        return result.json();
                    }
                    throw result;
                })
                .then(response => {
                    setrepoFunction(response.data.user.pinnedItems.edges);
                })
                .catch(function (error) {
                    console.error(
                        `${error} (because of this error, nothing is shown in place of Projects section. Also check if Projects section has been configured)`
                    );
                    setrepoFunction("Error");
                });
        };
        getRepoData();
    }, []);

    function setrepoFunction(array) {
        setrepo(array);
    }

    if (
        !(typeof repo === "string" || repo instanceof String) &&
        openSource.display
    ) {
        return (
            <Suspense fallback={renderLoader()}>
                <div
                    className={
                    isDark
                        ? "dark-mode section-container opensource-container"
                        : "section-container opensource-container"
                    }
                    id="opensource"
                >
                    <div className="opensource-section" id="opensource-section">
                        <h1 className="opensource-heading section-heading">{t("opensource.title")}</h1>
                        <div className="repo-cards-div-main">
                            {repo.map((v, i) => {
                                if (!v) {
                                    console.error(
                                        `Github Object for repository number : ${i} is undefined`
                                    );
                                }
                                return (
                                    <GithubRepoCard repo={v} key={v.node.id} isDark={isDark}/>
                                );
                            })}
                        </div>
                        <Button
                            text={t("opensource.moreProjects")}
                            className="project-button"
                            href={socialMediaLinks.github}
                            newTab={true}
                        />
                    </div>
                </div>
            </Suspense>
        );
    } else {
        return <FailedLoading/>;
    }
}
