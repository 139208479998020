import React, { useContext } from "react";
import { useParams } from 'react-router-dom'; // If using React Router
import matter from 'gray-matter';
import { Buffer } from 'buffer';
import MarkdownRenderer from './MarkdownRenderer'

import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";
import './ProjectDetails.scss';

// Polyfill global Buffer (required for gray-matter in the browser)
if (!global.Buffer) {
  global.Buffer = Buffer;
}

function ProjectDetails() {

  const { isDark } = useContext(StyleContext);
  const { t, i18n } = useTranslation();
  
  const { projectId } = useParams();
  const [projectDetails, setProjectDetails] = React.useState(null);
  const [metadata, setMetadata] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  // Fetch project details when component mounts
  React.useEffect(() => {
    async function fetchProjectDetails() {
      try {
        const response = await fetch(`/project-details/${projectId}.md`);
        if (!response.ok) {
          throw new Error(`Failed to fetch project details: ${response.statusText}`);
        }
        const markdown = await response.text();
        // Use gray-matter to parse the front matter and content
        const { data, content } = matter(markdown);
        setMetadata(data);  // Set the front matter metadata
        setProjectDetails(content);  // Set the markdown content
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
        console.error('Error fetching project details:', error);
      }
    }

    if (projectId) {
      fetchProjectDetails();
    } else {
      setError('Project ID not found in URL.');
      setLoading(false);
    }
  }, [projectId]);

  if (loading) {
    return <div className="project-details-container"><p>Loading project details...</p></div>;
  }

  if (error) {
    return <div className="project-details-container"><p>Error: {error}</p></div>;
  }

  return (
    <div className={ isDark ? "dark-mode project-details-container" : "project-details-container" } >
      {metadata && (
        <div className={ isDark ? "dark-mode project-metadata" : "project-metadata"} >
          <h1 className={ isDark ? "dark-mode project-title" : "project-title"} >{metadata.title}</h1>
          <p className={ isDark ? "dark-mode project-category" : "project-category"} >Category: {metadata.category}</p>
        </div>
      )}
      {projectDetails ? (
        <MarkdownRenderer className="markdown">{projectDetails}</MarkdownRenderer>
      ) : (
        <p>No project details available.</p>
      )}
    </div>
  );
}


export default ProjectDetails;
