import React, {useContext} from "react";
import "./Projects.scss";
import {projects} from "../../portfolio";
import {Fade} from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";

export default function Projects() {
  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    var win = window.open(url, "_blank");
    win.focus();
  }

  const {isDark} = useContext(StyleContext);
  const { t, i18n } = useTranslation();
  if (!projects.display) {
    return null;
  }
  return (
    <div
      className={
        isDark
          ? "dark-mode section-container projects-container"
          : "section-container projects-container"
      }
      id="projects"
    >
      <div className="projects-section" id="projects-section">
        <h2 className="projects-heading section-heading">{t("projects.title")}</h2>
        <div className="projects-wrapper">
          {projects.projects.map((project, i) => {
            return (
              <div
                key={i}
                className={
                  isDark
                    ? "dark-mode project-card project-card-dark"
                    : "project-card project-card-light"
                }
              >
                <div className="project-detail">
                  <h5
                    className={isDark ? "dark-mode card-title" : "card-title"}
                  >
                    {project.projectName}
                  </h5>
                  <p
                    className={
                      isDark ? "dark-mode card-subtitle" : "card-subtitle"
                    }
                  >
                    {project.projectDesc}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
