import React, {useContext} from "react";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import { useTranslation } from 'react-i18next';
import {
  greeting,
  workExperiences,
  educationInfo,
  skillsSection,
  openSource,
//  blogSection,
  talkSection,
  achievementSection
} from "../../portfolio";

function Header() {
  const {isDark} = useContext(StyleContext);
  const { t, i18n } = useTranslation();
  
  const toggleLanguage = () => {
    const nextLanguage = i18n.language === 'en' ? 'zh' : 'en';
    i18n.changeLanguage(nextLanguage);
  };

  const viewExperience = workExperiences.display;
  const vieweducation = educationInfo.display;
  const viewOpenSource = openSource.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  // const viewBlog = blogSection.display;
  const viewBlog = true;
  const viewTalks = talkSection.display;

  const { language } = i18n;
  const isEnglish = language === 'en';

  return (
    <header className={isDark ? "dark-menu header" : "header"}>
      <a href="/" className="logo">
        <span className="grey-color"> &lt;</span>
        <span className="logo-name">{greeting.username}</span>
        <span className="grey-color">/&gt;</span>
      </a>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label
        className="menu-icon"
        htmlFor="menu-btn"
        style={{color: "white"}}
      >
        <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
      </label>
      <ul className={isDark ? "dark-menu menu" : "menu"}>
        {viewSkills && (
          <li>
            <a href="#skills">{t("header.skills")}</a>
          </li>
        )}
        {viewExperience && (
          <li>
            <a href="#experience">{t("header.experiences")}</a>
          </li>
        )}
        {vieweducation && (
          <li>
            <a href="#education">{t("header.education")}</a>
          </li>
        )}
        {viewOpenSource && (
          <li>
            <a href="#opensource">{t("header.open_source")}</a>
          </li>
        )}
        {viewAchievement && (
          <li>
            <a href="#achievements">{t("header.achievements")}</a>
          </li>
        )}
        {viewBlog && (
          <li>
            <a href="https://blog.zhengye.me/" target="_blank">{t("header.blogs")}</a>
          </li>
        )}
        {viewTalks && (
          <li>
            <a href="#talks">{t("header.talks")}</a>
          </li>
        )}
        <li>
          <a href="#contact">{t("header.contact")}</a>
        </li>
        <li>
          <button className={isDark ? "dark-menu language-toggle" : "language-toggle"} onClick={toggleLanguage}>
            <span className="icon">&#x1F310;</span>
            {i18n.language === 'en' ? '中文' : 'EN'}
          </button>        
        </li>
        <li>
          <a>
            <ToggleSwitch />
          </a>
        </li>
      </ul>
    </header>
  );
}
export default Header;
