// Projects.js

import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import {Fade} from "react-awesome-reveal";
import { Typography, Card, CardContent, Grid } from "@mui/material";

import { useTranslation } from 'react-i18next';
import StyleContext from "../../contexts/StyleContext";

import "./Projects.scss"

function Projects() {
  const {isDark} = useContext(StyleContext);
  const { t, i18n } = useTranslation();
  const [projectsByCategory, setProjectsByCategory] = useState({});

  useEffect(() => {
    async function fetchProjectData() {
      try {
        const response = await fetch('/project-list.md');
        const markdown = await response.text();
        setProjectsByCategory(parseMarkdown(markdown));
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    }

    fetchProjectData();
  }, []);

  const parseMarkdown = (markdown) => {
    const projects = {};
    const lines = markdown.split('\n');

    let currentCategory = '';
    let currentProject = null;

    const projectRegex = /^### \[(.+?)\]\((.+?)\)$/;

    lines.forEach(line => {
      line = line.trim();
      if (!line) return;

      if (line.startsWith('## ')) {
        currentCategory = line.substring(3).trim();
        if (!projects[currentCategory]) {
          projects[currentCategory] = [];
        }
      } else if (projectRegex.test(line)) {
        if (currentProject) {
          projects[currentCategory].push(currentProject);
        }
        const [, title, link] = projectRegex.exec(line);
        currentProject = { title, link, description: '' };
      } else if (currentProject) {
        currentProject.description += line + '\n';
      }
    });

    if (currentProject) {
      projects[currentCategory].push(currentProject);
    }

    return projects;
  };

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className={isDark ? "dark-mode section-container projects-container" : "section-container projects-container"} id="projects">
        <div className={isDark ? "dark-mode projects-section" : "projects-section"}>
          <h2 className={isDark ? "dark-mode projects-heading" : "projects-heading"}>Projects</h2>
            {Object.keys(projectsByCategory).map((category, categoryIndex) => (
              <div key={categoryIndex} className="category-section">
                <h3 className={isDark ? "dark-mode category-text" : "category-text"}>{category}</h3>
                <Grid container spacing={2}
                  className={
                    isDark
                      ? "dark-mode projects-content"
                      : "projects-content"
                  }
                >
                  {projectsByCategory[category].map((project, projectIndex) => (
                    <Grid item xs={12} sm={6} md={4} key={projectIndex}>
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                          bgcolor: isDark ? "dark.main" : "background.paper",
                        }}
                        className={
                          isDark
                            ? "dark-mode i-project-card"
                            : "i-project-card"
                        }
                      >
                        <CardContent style={{"minWidth": "100px"}}> 
                            <h4 className="project-title-text">
                              {project.link.startsWith('http') ? (
                                <a href={project.link} target="_blank" rel="noopener noreferrer">{project.title}</a>
                              ) : (
                                <Link to={project.link}>{project.title}</Link>
                              )}
                            </h4>
                            <ReactMarkdown>{project.description}</ReactMarkdown>
                        </CardContent>
                      </Card>
                    </Grid>
                ))}
                </Grid>
              </div>
            ))}
        </div>
      </div>
    </Fade>
  );
}

export default Projects;


